import React from "react"
import BuscarTutorialBlock from '../global/components/blocks/buscar/BuscarTutorialBlock'

import Layout from "../global/components/layout"
import SEO from "../global/components/seo"

const buscar = () => (
  <Layout>
    <SEO title="Buscar" />
    <BuscarTutorialBlock></BuscarTutorialBlock>
  </Layout>
)

export default buscar
